<template>
  <div id="score" class="table-responsive">
    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>
    <table style="position: relative" class="overflow">
      <thead :class="{ developGreen: develop === 'adt' }">
        <tr class="headSet">
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'N') ||
                (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <th>
              Pos
            </th>
            <th class="NameLeft">
              Name
            </th>
            <template v-if="tablePick === 'Live' || tablePick === 'Score'">
              <th class="mobnone">
                Nat
              </th>
            </template>
            <th
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'N')
              "
            >
              Par
            </th>
            <th
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'Y')
              "
            >
              Points
            </th>
            <template v-if="this.title.includes('Scoreboard')">
              <th
                v-for="(i, roundIt) in range(1, roundsPlayed)"
                :key="roundIt"
                class="hideOnMob"
              >
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>
              <template v-if="data.scores.scores_entry[0].score_found === 'Y'">
                <th v-if="roundsPlayed > 1">
                  Score
                </th>
              </template>
              <template v-if="roundsPlayed > 1 && tablePick === 'Score'">
                <th
                  class=""
                  v-if="
                    config.VUE_APP_PLAYER_ICON === 'Y' &&
                      data.scores.scores_entry[0].score_found === 'Y' &&
                      data.last_round_complete === 'Y'
                  "
                ></th>
              </template>
            </template>
            <!-- <template v-if="tablePick === 'Live'"> -->
            <template
              v-if="
                data.scores.scores_entry[0].score_found === 'N' ||
                  data.round_head == 'Latest Scores'
              "
            >
              <th>
                Thru
              </th>
            </template>
            <!-- <template
              v-if="
                this.title.includes('Only') ||
                  this.title.includes('Live') ||
                  tablePick === 'Live'
              "
            >
              <th class="mobnone"></th>
            </template> -->
          </template>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'Y') ||
                (tablePick === 'Live' && data.match_play === 'Y')
            "
          >
            <th>
              Name
            </th>
            <th>
              Score
            </th>
            <th>
              Thru.
            </th>
            <th>
              Score
            </th>
            <th>
              Name
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td class="posnum" v-else-if="index !== 0">
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <template v-if="isMobile(true)">
                <td
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'N'
                  "
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                  class="NameLeft"
                >
                  <span
                    v-if="
                      data.score_type === 'P' ||
                        (data.tt_type === 'P' && data.stableford === 'N')
                    "
                  >
                    {{ tabledata.name }}
                    <span v-if="tabledata.pro_ind === 'Am'">(a)</span>
                  </span>
                  <span
                    v-if="
                      data.score_type === 'P' ||
                        (data.tt_type === 'P' && data.stableford === 'Y')
                    "
                  >
                    {{ tabledata.name }}
                    <span v-if="tabledata.pro_ind === 'Am'">(a)</span>
                  </span>
                  <span v-if="tabledata.sponsored === 'Y' && develop === 'apga'"
                    ><b-img
                      class="titliestMob"
                      :src="config.VUE_APP_TITLEIST"
                    ></b-img
                  ></span>
                  <span
                    v-if="
                      tabledata.entry_sponsored === 'Y' && develop === 'apga'
                    "
                    ><b-img
                      style="width: 15px; margin-left: 13px;"
                      src="https://assets.asiantour.com/asian-tour/2022/06/theopen.png"
                    ></b-img
                  ></span>
                  <span
                    v-if="data.multi_course === 'Y'"
                    :class="tabledata['course_colour_R' + data.pft_round]"
                    class="multiDot-size-report"
                    >•</span
                  >
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
                <td
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'Y'
                  "
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                  class="NameLeft"
                >
                  {{ tabledata.name }}
                  <span v-if="tabledata.sponsored === 'Y' && develop === 'apga'"
                    ><b-img
                      class="titliestMob"
                      :src="config.VUE_APP_TITLEIST"
                    ></b-img
                  ></span>
                  <span
                    v-if="
                      tabledata.entry_sponsored === 'Y' && develop === 'apga'
                    "
                    ><b-img
                      style="width: 15px; margin-left: 13px;"
                      src="https://assets.asiantour.com/asian-tour/2022/06/theopen.png"
                    ></b-img
                  ></span>
                  <span
                    v-if="data.multi_course === 'Y'"
                    :class="tabledata['course_colour_R' + data.pft_round]"
                    class="multiDot-size-report"
                    >•</span
                  >
                </td>
              </template>
              <template v-else>
                <td
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'N'
                  "
                  @click="playerCard(tabledata.id)"
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                  class="NameLeft"
                >
                  <span
                    v-if="
                      data.score_type === 'P' ||
                        (data.tt_type === 'P' && data.stableford === 'N')
                    "
                  >
                    {{ tabledata.name }}
                    <span v-if="tabledata.pro_ind === 'Am'">(a)</span>
                  </span>
                  <span
                    v-if="
                      data.score_type === 'P' ||
                        (data.tt_type === 'P' && data.stableford === 'Y')
                    "
                  >
                    {{ tabledata.name }}
                    <span v-if="tabledata.pro_ind === 'Am'">(a)</span>
                  </span>
                  <span v-if="tabledata.sponsored === 'Y' && develop === 'apga'"
                    ><b-img
                      class="titliestMob"
                      :src="config.VUE_APP_TITLEIST"
                    ></b-img
                  ></span>
                  <span
                    v-if="
                      tabledata.entry_sponsored === 'Y' && develop === 'apga'
                    "
                    ><b-img
                      style="width: 15px; margin-left: 13px;"
                      src="https://assets.asiantour.com/asian-tour/2022/06/theopen.png"
                    ></b-img
                  ></span>
                  <span
                    v-if="data.multi_course === 'Y'"
                    :class="tabledata['course_colour_R' + data.pft_round]"
                    class="multiDot-size-report"
                    >•</span
                  >
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
                <td
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'Y'
                  "
                  @click="playerCard(tabledata.id)"
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                  class="NameLeft"
                >
                  {{ tabledata.name }}
                  <span v-if="tabledata.sponsored === 'Y' && develop === 'apga'"
                    ><b-img
                      class="titliestMob"
                      :src="config.VUE_APP_TITLEIST"
                    ></b-img
                  ></span>
                  <span
                    v-if="
                      tabledata.entry_sponsored === 'Y' && develop === 'apga'
                    "
                    ><b-img
                      style="width: 15px; margin-left: 13px;"
                      src="https://assets.asiantour.com/asian-tour/2022/06/theopen.png"
                    ></b-img
                  ></span>
                  <span
                    v-if="data.multi_course === 'Y'"
                    :class="tabledata['course_colour_R' + data.pft_round]"
                    class="multiDot-size-report"
                    >•</span
                  >
                </td>
              </template>
              <template v-if="tablePick === 'Score' || tablePick === 'Live'">
                <td class="mobHide mobnone" v-if="tabledata.pos !== 'CUT'">
                  <span v-if="tabledata.nationality === ''"></span>
                  <span v-else>
                    <template v-if="represents_flag_type === 'N'">
                      {{ tabledata.nationality }}
                    </template>
                    <template v-else>
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL + tabledata.nationality)
                            | lowercase
                        "
                      />
                    </template>
                  </span>
                </td>
              </template>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td
                  v-else-if="tabledata.pos !== 'CUT'"
                  :class="{ nonScore: tabledata.vspar == 'RTD' }"
                >
                  {{ tabledata.vspar }}
                </td>
              </template>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'Y')
                "
              >
                <td v-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.score }}
                </td>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'Y'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <!-- <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }} </span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span> -->
                  <span v-if="tabledata['score_R' + i] > 0" class="plus">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span v-else-if="tabledata['score_R' + i] < 0" class="minus">
                    {{ tabledata["score_R" + i] }}
                  </span>
                  <span v-else class="zero">
                    {{ tabledata["score_R" + i] }}
                  </span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class=""
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'N'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span
                    v-else
                    :class="{ nonScore: tabledata.vspar == 'RTD' }"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  data.round_head == 'Latest Scores' ||
                    tabledata.score_found == 'N'
                "
              >
                <td style="color: #000;" v-if="tabledata.pos !== 'CUT'">
                  <template
                    v-if="tabledata.holes && tabledata.holes.length > 0"
                  >
                    <span v-show="tabledata.holes != '18'"
                      >{{ tabledata.holes
                      }}<span v-if="tabledata['tee_R' + data.pft_round] > 1"
                        >*</span
                      ></span
                    >
                    <span v-show="tabledata.holes == '18'">F</span>
                  </template>
                  <template v-else>
                    {{ tabledata["time_R" + data.pft_round] }}
                  </template>
                  <!-- <span v-show="tabledata.holes && tabledata.holes.length > 0 && tabledata.holes != '0'">
                    <span v-show="tabledata.holes != '18'">{{tabledata.holes}}</span>
                    <span v-show="tabledata.holes == '18'">F</span>
                  </span>
                  <span v-hide="tabledata.holes && tabledata.holes.length > 0 && tabledata.holes != '0'">{{ tabledata["time_R" + data.pft_round] }}</span>
                  <span v-show="tabledata.vspar == 'RTD'">{{tabledata.vspar}}</span> -->
                  <!-- <span v-if="tabledata.holes !== ''" style="color:black;" >
                    {{ tabledata["time_R" + data.pft_round] }}
                  </span> -->
                  <!-- <span v-if="tabledata.holes !== 18">
                    {{ tabledata.holes }}
                    <span
                      v-if="
                        tabledata['tee_R' + roundsPlayed] === '10' &&
                          tabledata['time_R' + roundsPlayed].length < 0
                      "
                      >*</span
                    >
                  </span>
                  <span v-else>
                    <span class="FinishedCap">
                      F
                    </span>
                    <span
                      class="starStart"
                      v-if="tabledata['tee_R' + roundsPlayed] === '10'"
                      >*</span
                    >
                  </span> -->
                </td>
              </template>
              <!-- <td
                class="mobnone"
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.member_no">
                  <font-awesome-icon
                    style="color:#f2af15!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td> -->
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'Y') ||
                  (tablePick === 'Final' && data.match_play === 'Y')
              "
            >
              <td
                :title="tabledata.member_no_1"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_1 == 'W'
                }"
              >
                {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
              </td>
              <td v-if="tabledata.status_1 === 'W'" class="win">
                {{ tabledata.full_score_1 }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="tabledata.holes === ''">
                  {{ tabledata.time }}
                </span>
                <span v-else-if="tabledata.holes < 18">
                  {{ tabledata.holes
                  }}<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
                <span v-else>
                  F<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
              </td>
              <td v-if="tabledata.status_2 === 'W'" class="win">
                {{ tabledata.full_score_2 }}
              </td>
              <td v-else></td>
              <td
                :title="tabledata.member_no_2"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_2 == 'W'
                }"
              >
                {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
              </td>
            </template>
          </tr>
          <template v-if="isMobile(true)"> </template>
          <template v-else>
            <tr
              @click.stop="rowClicked(rowClicked === -1)"
              v-if="index === clickedRow"
              class="tr-kids"
              :key="index + Math.random()"
            >
              <td id="reportCard" colspan="11">
                <PlayerReportCard
                  :playerData="playerData"
                  :playerDataTeam="playerDataTeam"
                  :data="data"
                  :config="config"
                  :actualRound="data.pft_round"
                  :roundsPlayed="data.pft_round"
                  :scoreType="data.scores_type"
                  :team="team"
                  :courses="courses"
                  :stablford="data.stableford"
                  :develop="develop"
                />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
// import axios from "axios";
import PlayerReportCard from "@/ocs/reports/scores/scorecards/components/playerReportCard.vue";
export default {
  name: "score",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "develop",
    "season",
    "code",
    "config",
    "team",
    "match",
    "showGender",
    "represents_flag_type",
    "Stableford"
  ],
  components: { PlayerReportCard },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: false,
      cardID: [],
      playerCardData: [],
      win: "win"
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 500) {
        return true;
      } else {
        return false;
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (
        this.tablePick === "Live" ||
        (this.tablePick === "Score" && this.clickedRow !== -1)
      )
        this.clickedRow = index;
      else this.clickedRow = true;
    },
    playerCard: function(playerNo) {
      var playerData = {};
      // var cardID = event.target.getAttribute("title");
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 998");
          console.log(this.playerData);
          break;
        }
      }
      for (var t of this.data.team_scores.team_scores_entry) {
        console.log("in scores");
        if (t.member_no == playerNo) {
          this.playerDataTeam = t;
          console.log("playerData 998");
          console.log(this.playerDataTeam);
          break;
        }
      }
      // return (
      //   (this.cardID = cardID),
      //   apiCall
      //     .playercard(this.data.season, this.code, this.cardID)
      //     .then(({ data }) => {
      //       this.playerCardData = data;
      //       if (Array.isArray(this.data.courses.courses_entry)) {
      //         this.courses = this.data.courses.courses_entry
      //         console.log('One')
      //       } else {
      //         this.courses = [this.data.courses.courses_entry]
      //         console.log('Two')
      //       }
      //       this.loading = false;
      //     })
      //     .catch(error => {
      //       console.log(error);
      //       throw error;
      //     })
      // );
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "N") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "N")
      )
        picked = "Score";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "Y") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "Y")
      )
        picked = "Live";
      else if (this.title.includes("Final")) picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (
          (this.title.includes("Scores") && this.team === "N") ||
          (this.title.includes("Scoreboard") && this.team === "N") ||
          (this.title.includes("Live") && this.team === "N")
        )
          if (Array.isArray(this.data.scores.scores_entry)) {
            reportData = this.data.scores.scores_entry;
            // console.log('One')
          } else {
            reportData = [this.data.scores.scores_entry];
            // console.log('Two')
          }
        else if (
          (this.title.includes("Scores") && this.team === "Y") ||
          (this.title.includes("Scoreboard") && this.team === "Y") ||
          (this.title.includes("Live") && this.team === "Y")
        )
          reportData = this.data.team_scores.team_scores_entry;
        return reportData;
      } else {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          (this.title.includes("Live") && this.team === "N")
        )
          reportData = this.data.scores.scores_entry.slice(0, 6);
        else if (
          (this.title.includes("Scores") && this.team === "Y") ||
          (this.title.includes("Scoreboard") && this.team === "Y") ||
          (this.title.includes("Live") && this.team === "Y")
        )
          reportData = this.data.team_scores.team_scores_entry.slice(0, 6);
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      // console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
span.nonScore {
  font-size: 11px;
}
tr.tr-kids.hideMobile {
  display: none;
}
.overflow {
  overflow: hidden;
}
.titliestMob {
  width: 34px;
}
.minus {
  color: #1e90ff;
}
.plus {
  color: #a52a2a;
}
.zero {
  color: #000;
}
.none {
  display: none;
}
.female {
  color: #e33;
}
.male {
  color: #037;
}
span.multiDot-size-reportgender {
  font-size: 30px !important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
span.multiDot-size-report {
  float: right;
  font-size: 60px !important;
  line-height: 0px !important;
  position: relative;
  top: 12px;
}
tr.headSet > th {
  position: sticky;
  top: -1px;
}
td.up.colorblack {
  color: black;
}
td.NameCOlor {
  color: #337ab7 !important;
  cursor: pointer;
}
/* .title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
} */
/* .title > p {
  font-size: 16px !important;
  margin-bottom: 0;
} */
th {
  position: sticky;
  top: 0;
  /* background-color: #133f7b; */
  padding: 9px;
  font-size: 14px;
}
#reportTable {
  padding-bottom: 50px;
}
.developGreen > tr > th {
  background-color: #76c3bd !important;
}
.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  background-color: #d4d4d4;
  color: #0b3f7e;
}
.drawName {
  font-size: 14px !important;
}
.drawText {
  font-size: 14px !important;
  padding: 0 !important;
}

table {
  width: 100%;
}
tr > th:first-child {
  /* border-top-left-radius: 0px; */
}
tr > th:last-child {
  /* border-top-right-radius: 0px; */
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: #fff !important;
}
.even {
  background-color: #f9f9f9 !important;
}
tr:nth-child(odd) {
  background-color: #f9f9f9;
}
tr:nth-child(even) {
  background-color: #fff;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: black;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
}
td {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 8px !important;
  font-size: 14px !important;
  padding-left: 8px !important;
  font-weight: 400;
  vertical-align: middle;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
  height: 31px;
  position: relative;
  top: -1px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
@media only screen and (max-width: 768px) {
  td {
    font-size: 14px !important;
  }
  th {
    font-size: 14px;
    padding: 6px;
  }
  .mobHide {
    display: none;
  }
  th.mobnone {
    display: none;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .overflow {
    overflow: auto;
  }
  thead > tr {
    height: auto !important;
  }
  td.posnum {
    margin-top: 10px;
  }
  tr:nth-child(odd) {
    background-color: #f9f9f9;
    height: 65px;
  }
  tr:nth-child(even) {
    background-color: #fff;
    height: 65px;
  }
  .hideOnMob {
    display: none;
  }
  img.titliestMob {
    width: 34px;
  }
  th.mobnone {
    display: none;
  }
  span.multiDot-size-reportgender {
    font-size: 30px !important;
    line-height: 0px !important;
    position: relative;
    top: 8px;
  }
  span.multiDot-size-report {
    float: right;
    font-size: 60px !important;
    line-height: 0px !important;
    position: relative;
    top: 10px !important;
  }
}
</style>
