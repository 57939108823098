<template>
  <div>
    <div id="desktop" class="table-responsive scorecardMargin">
      <table id="scoreCard">
        <thead>
          <tr class="t3">
            <th>
              Hole
            </th>
            <th>
              1
            </th>
            <th>
              2
            </th>
            <th>
              3
            </th>
            <th>
              4
            </th>
            <th>
              5
            </th>
            <th>
              6
            </th>
            <th>
              7
            </th>
            <th>
              8
            </th>
            <th>
              9
            </th>
            <th>
              OUT
            </th>
            <th>
              10
            </th>
            <th>
              11
            </th>
            <th>
              12
            </th>
            <th>
              13
            </th>
            <th>
              14
            </th>
            <th>
              15
            </th>
            <th>
              16
            </th>
            <th>
              17
            </th>
            <th>
              18
            </th>
            <th>
              IN
            </th>
            <th>
              TOTAL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="t3">
            <td>
              Yds
            </td>
            <td
              v-for="(yard, index) in data.course_length_yards
                .split(',')
                .slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ yard }}
            </td>
            <td>
              {{ data.course_out_length.slice(1, 6) }}
            </td>
            <td
              v-for="(number, index) in data.course_length_yards
                .split(',')
                .slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ number }}
            </td>
            <td>
              {{ data.course_in_length.slice(1, 6) }}
            </td>
            <td>
              {{ data.course_total_length.slice(1, 6) }}
            </td>
          </tr>
          <tr class="t3">
            <td>
              Par
            </td>
            <td
              v-for="(yard, index) in data.course_par.split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              {{ yard }}
            </td>
            <td>
              {{ data.course_out_par.slice(1, 6) }}
            </td>
            <td
              v-for="(number, index) in data.course_par.split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              {{ number }}
            </td>
            <td>
              {{ data.course_in_par.slice(1, 6) }}
            </td>
            <td>
              {{ data.course_total_par }}
            </td>
          </tr>
          <tr v-for="(i, roundIt1) in range(1, roundsPlayed)" :key="roundIt1">
            <td>R{{ i }}</td>
            <td
              v-for="(r1, index) in playerData['hole_scores_R' + i].split(',').slice(0, 9)"
              :key="index + Math.random()"
            >
              <span
                class="twoUnder"
                v-if="playerData['hole_vspars_R' + i].split(',')[index] == -2"
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == -1"
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 1"
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="playerData['hole_vspars_R' + i].split(',')[index] == 2"
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td>{{ outVal(playerData['hole_scores_R' + i]) }}</td>
            <td
              v-for="(r1, index) in playerData['hole_scores_R' + i].split(',').slice(9, 18)"
              :key="index + Math.random()"
            >
              <span
                class="twoUnder"
                v-if="playerData['hole_vspars_R' + i].split(',').slice(9, 18)[index] == -2"
              >
                {{ r1 }}
              </span>
              <span
                class="oneUnder"
                v-else-if="playerData['hole_vspars_R' + i].split(',').slice(9, 18)[index] == -1"
              >
                {{ r1 }}
              </span>
              <span
                class="oneOver"
                v-else-if="playerData['hole_vspars_R' + i].split(',').slice(9, 18)[index] == 1"
              >
                {{ r1 }}
              </span>
              <span
                class="twoOver"
                v-else-if="playerData['hole_vspars_R' + i].split(',').slice(9, 18)[index] == 2"
              >
                {{ r1 }}
              </span>
              <span v-else>
                {{ r1 }}
              </span>
            </td>
            <td>{{ inVal(playerData['hole_scores_R' + i]) }}</td>
            <td>{{ totalVal(playerData['hole_scores_R' + i])}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "playerReportCard",
  props: ["data", "roundsPlayed","playerData", "config"],
  data() {
    return {
      oneUnder: "oneUnder",
      twoUnder: "twoUnder",
      oneOver: "oneOver",
      twoOver: "twoOver"
    };
  },
  computed: {

  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  }
};
</script>

<style scoped>
.scorecardMargin {
  margin-top: 1rem;
}
#scoreCard {
  width: 80%;
  text-align: center;
  margin-left: 10%;
}
#mobile {
  display: none;
}
.oneOver {
  color: #1e90ff;
}
.twoOver {
  color: #e07020;
}
.oneUnder {
  color: #a52a2a;
}
.twoUnder {
  color: #3c9933;
}
.t3 {
  background-color: #3E3E3E !important;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
tr:nth-child(odd) {
  background-color: white;
}
@media only screen and (max-width: 480px) {
  #scoreCard {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  #desktop {
    display: none;
  }
  #mobile {
    display: block;
  }
  ::v-deep .mt-3 {
    margin-top: 0 !important;
  }
  ::v-deep .nav-tabs .nav-link.active {
    color: white;
    background-color: #263056;
    text-align: center;
    width: 100%;
    border-color: #263056;
  }
  ::v-deep .nav-tabs .nav-link {
    text-align: center;
    width: 100%;
  }
  ::v-deep .nav-link {
    color: black;
    width: 50%;
  }
  ::v-deep .nav-item {
    width: 50%;
  }
  .blue {
    background-color: #263056 !important;
    color: white;
  }
}
</style>
