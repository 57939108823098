<template>
  <div id="results">
    <table>
      <thead>
        <tr>
          <template> </template>
          <template v-if="tablePick === 'Final'">
            <th>
              Pos
            </th>

            <th>
              Player Name
            </th>
            <th class="mobHide">
              Nat.
            </th>
            <th>
              Winnings
            </th>
            <template>
              <th>
                Par
              </th>
              <th>
                Score
              </th>
              <th v-for="(i, roundIt) in range(1, roundsPlayed)" :key="roundIt">
                R{{ i }}
              </th>
              <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
            </template>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <template v-if="tablePick === 'Final'">
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.pos }}
              </td>
              <td
                class="posnum"
                v-if="
                  index !== 0 && tabledata.pos !== 'CUT'
                "
              >
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0 && tabledata.pos !== 'CUT'">
                <span>
                  {{ tabledata.tied + tabledata.pos }}
                </span>
              </td>
              <td v-else-if="tabledata.pos !== 'CUT'" class="noPos"><span>.</span></td>

              <td
                v-if="tabledata.playerno !== 'Cat' && tabledata.pos !== 'CUT'"
                @click="playerCard"
                :title="tabledata.member_no"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
               
                  {{ tabledata.name }}
                  <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                  <span v-if="tabledata.sponsored === 'Y'"
                    ><b-img class="sponsoredImage" :src="data.sponsor_indicator_logo"></b-img
                  ></span>
                
              </td>
              <td class="mobHide" v-if="tabledata.pos !== 'CUT'">
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
               
                </span>
              </td>
              <td v-if="tabledata.pos !== 'CUT'">
                {{ tabledata.winnings }}
              </td>
              <template v-if="tabledata.pos !== 'CUT'">
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.vspar }}
                </td>
                <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                  {{ tabledata.score }}
                </td>
                <td
                  v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                  class="down"
                >
                  {{ tabledata.score }}
                </td>
                <td v-else-if="roundsPlayed > 1">
                  {{ tabledata.score }}
                </td>
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else>{{ tabledata["score_R" + i] }}</span>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <a :href="'/playerprofile/' + tabledata.playerno">
                  <font-awesome-icon
                    style="color:#337ab7!important;"
                    id="user"
                    class="icon"
                    :icon="['fa', 'user']"
                  />
                </a>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "results",
  props: ["data", "previousData", "home", "title", "season", "code", "team", "config", 'match'],
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win",
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
      apiCall
      .playercard(this.data.season, this.data.code, this.cardID)
      .then(({ data }) => {
        this.playerCardData = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      })
      );
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Tournament")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
      
        picked = "Final";
        else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Tournament"))
          reportData = this.data.entries.entries_entry;
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry;
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          reportData = this.data.entries.entries_entry.slice(0, 4);
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, 4);
        else if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, 4);
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        )
          reportData = this.data.result.result_entry.slice(0, 4);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>
<style scoped>
.sponsoredImage {
  width: 50px;
  margin-left: 10px;
}
td.rightScore {
  text-align: right;
}
td.rightName {
  text-align: center;
}
th.rightScore {
  text-align: right;
}
th.rightName {
  text-align: center;
}
span.drawMatch {
  position: relative;
  top: -5px;
}
tr > td.drawTd {
  padding-bottom: 0px;
}
td.namePlayer {
  text-align: left!important;
}
th.namePlayer {
  text-align: left!important;
}
a.drawNameColor {
  color: #000;  
}
a.playerName {
  color: #000;
}
h3.fullnameandTitle {
  font-size: 12pt!important;
  text-align: left;
  padding-bottom: 22px;
}
td.catD {
  background: #EB0A1E;
  color: #fff;
}
.drawPos{
  max-width: 100%!important;
  text-align: -webkit-center!important;
  margin-top: 0px!important;
}
.title {
  text-align: center;
  background-color: #3E3E3E;
  color: white;
  padding-top: 1em;
}
.centerTitle>h3{
text-align: center!important;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 13pt!important;
  font-weight: 400;
  padding-bottom: 2em;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #3E3E3E;
  color: white!important;
  padding-bottom: 15px;
  font-size: 10pt;
  font-weight: normal;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  /* background-color: rgba(0,0,0,.05); */
  background: #e8e8e8;
}
td{
  /* padding: .75rem; */
  padding: 8px;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
  font-size: 10pt;
}
tr:nth-child(even) {
  background: rgba(0,0,0,.05)
};
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  position: relative;
  top: -5px;
  /* max-width: 58px; */
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  /* box-shadow: 0 0 3px #555; */
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

.win {
  background-color: #3E3E3E!important;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  th {
    position: sticky;
    top: 0;
    background-color: #3E3E3E;
    color: white!important;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  .mobHide{
    display: none;
  }
}

</style>




