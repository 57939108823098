<template>
  <div id="entries">
    <!-- DO NOT REMOVE WILL BREAK -->

    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>

    <!-- DO NOT REMOVE WILL BREAK -->

    <table>
      <thead>
        <tr>
          <template v-if="tablePick === 'Entries'">
            <th>
              No
            </th>
            <th class="LeftAlign">
              Name
            </th>
            <th>
              Nat
            </th>
            <th>
              Entry Status
            </th>
            <th class="mobHide">
              Exempt Rank
            </th>
            <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'" class=""></th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            :class="{ drawNoborder: tablePick === 'Draw' }"
          >
            <template v-if="tablePick === 'Entries'">
              <td
                class="posnum"
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.serial_no }}
              </td>
              <td colspan="11" class="catD" v-if="tabledata.playerno === 'Cat'">
                {{ tabledata.exempt_desc }}
              </td>
              <td colspan="11" class="cut" v-if="tabledata.playerno === 'EOF'">
                {{ tabledata.exempt_desc }}
              </td>

              <td
                class="LeftAlign"
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.name }} <span v-if="tabledata.amateur_ind == 'A'">(a)</span>
                <template v-if="showGender == 'Y'">
                  <template v-if="tabledata.gender == 'M'">
                    <span class="male"></span>
                  </template>
                  <template v-else>
                    <span class="female"></span>
                  </template>
                </template>
              </td>
              <td
                class="mobHide"
                v-if="
                  tabledata.pos !== 'CUT' &&
                    tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF'
                "
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.status_text }}
              </td>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.exempt_rank }}
              </td>
              <td
                class=""
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    config.VUE_APP_PLAYER_ICON === 'Y'
                "
              >
                <template v-if="tabledata.profile == 'Y'">
                  <a
                    :href="'/playerprofile/' + tabledata.playerno"
                    class="playerInfoLink"
                  >
                    <span class="playerInfo">
                      <span v-if="home !== true" class="mobnone">
                        Player Info
                      </span>
                      <span v-else> </span>
                      <span class="iconRight">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </span>
                    </span>
                  </a>
                </template>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "entries",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
    "showGender"
  ],
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win"
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Tournament"))
          if (Array.isArray(this.data.entries.entries_entry)) {
            // reportData = this.data.entries.entries_entry;
            reportData = this.data.entries.entries_entry;
            console.log("One");
          } else {
            reportData = [this.data.entries.entries_entry];
            console.log("Two");
          }
        return reportData;
      } else {
        if (this.title === "Tournament Entries")
          if (Array.isArray(this.data.entries.entries_entry)) {
            // reportData = this.data.entries.entries_entry.slice(0, 6);
            reportData = this.data.entries.entries_entry.slice(0, 6);
            console.log("One");
          } else {
            reportData = [this.data.entries.entries_entry].slice(0, 6);
            console.log("Two");
          }
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>
