<template>
  <div>
    <b-carousel
      id="carousel-1"
      indicators
      background="#F0F0F0"
      img-width="1024"
      img-height="280"
      controls
    >
    <!-- controls -->
      <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col v-for="(upComingTours, index) in futureEventFilter.slice(0,2)" :key='index'>
            <template v-if="upComingTours.course_image_file_1.length > 0">
              <b-card
                :title="upComingTours.full_name"
                :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
               <!-- :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1" -->
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
            <template v-else>
              <b-card
                :title="upComingTours.full_name"
                img-src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
          </b-col>
        </b-row>
      </b-carousel-slide>
      <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col v-for="(upComingTours, index) in futureEventFilter.slice(2,4)" :key='index'>
            <template v-if="upComingTours.course_image_file_1.length > 0">
              <b-card
                :title="upComingTours.full_name"
                :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
               <!-- :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1" -->
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
            <template v-else>
              <b-card
                :title="upComingTours.full_name"
                img-src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
          </b-col>
        </b-row>
      </b-carousel-slide>
      <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col v-for="(upComingTours, index) in futureEventFilter.slice(4,6)" :key='index'>
            <template v-if="upComingTours.course_image_file_1.length > 0">
              <b-card
                :title="upComingTours.full_name"
                :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
               <!-- :img-src="DEFIMAGESAMS3 + 'mst' + '/' + 'courses' + '/' + Year + '/' + upComingTours.course_code + '/' + upComingTours.course_image_file_1" -->
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
            <template v-else>
              <b-card
                :title="upComingTours.full_name"
                img-src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2 sliderTitle"
              >
                <b-card-text>
                  <b-row>
                    <b-col lg="8">
                      <span class="sliderdetails courseDetails">
                        {{ upComingTours.course }}
                      </span>
                      <br class="hideBR" />
                      <span class="sliderdetails">
                        {{ upComingTours.dates }}
                      </span>
                    </b-col>
                    <b-col lg="4" v-if="upComingTours.sponsor_logo !== ''">
                      <img class="logo" :src="upComingTours.sponsor_logo" onerror="this.src='https://mst.ocs-mst.com/pmws-logos/logos/tourlogo.jpg';"/>
                      <!-- <img class="logo" :src="upComingTours.sponsor_logo.replace('http://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/').replace('https://info.tartanprotour.co.uk/', DEFIMAGESAMS3 + 'tpt' + '/')" /> -->
                    </b-col>
                  </b-row>
                </b-card-text>

                <b-button
                  :to="'/tournament/' + upComingTours.code + '/' + season"
                  >Tournament Info</b-button
                >
              </b-card>
            </template>
          </b-col>
        </b-row>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "slider",
  props: ["Year", "config", "DEFIMAGESAMS3"],
  full_name: "upComing",
  data() {
    return {
      slide: 0,
      sliding: false,
      imgURL: this.config.VUE_APP_COURSE_URL,
      file: "File_1.jpg",
      upComingTours: [],
      img: this.config.VUE_APP_DEFAULT_COURSE,
      season: this.Year,
    };
  },
  computed: {
    futureEventFilter: function() {
      return this.upComingTours.filter((upComing) => upComing.days_away > 0);
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = this.img;
    },
    imageLoadOnError(e) {
      e.target.src = "https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png"
    }
  },
  created() {
    apiCall 
      .upComing(this.season)
      .then(({ data }) => {
        this.upComingTours = data.tournaments.tournaments_entry;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep span.sliderdetails.courseDetails {
  margin-top: 10px;
}
::v-deep .carousel-control-prev-icon {
  background-image: none;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: -40px;
  color: grey;
}
::v-deep .carousel-control-next-icon {
  background-image: none;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-135deg);
  right: -40px;
  color: grey;
}
.homeSplash {
  margin-top: -15%;
}
::v-deep .carousel-caption {
  position: absolute;
  right: 9%;
  bottom: 20px;
  left: 9%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
#carouselMob {
  display: none;
}
::v-deep ol#carousel-1___BV_indicators_ {
  display: none !important;
}
.card {
  max-width: 410rem !important;
  color: black;
  text-align: left;
  /* height: 450px; */
  height: 500px;
  overflow: hidden;
}
.logo {
  height: 60px;
  text-align: center;
  float: none;
  margin: auto;
  display: block;
}
::v-deep .btn {
  background-color: #EB0A1E;
  border-color: white;
  font-size: 1rem;
  border-radius: 0;
  width: 40%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
}
::v-deep .btn:hover {
  background-color: #B91B29;
  border-color: white;
}
::v-deep .mb-2,
.my-2 {
  margin-bottom: -10.5rem !important;
}
.sliderdetails {
  font-size: 13pt;
  margin-top: -8px;
  display: block;
}
.sliderTitle {
  color: #000;
  font-weight: 400;
  font-size: 15pt;
  /* box-shadow: 4px 3px 5px lightgrey; */
}
::v-deep .carousel-inner {
  padding-bottom: 12em;
}
::v-deep .card-title {
  font-size: 13pt;
}
::v-deep span.carousel-control-next-icon {
  /* width: 30px; */
  /* height: 30px; */
  margin-top: 30px;
}
::v-deep span.carousel-control-prev-icon {
  /* width: 30px; */
  /* height: 30px; */
  margin-top: 30px;
}
  ::v-deep .carousel-item {
    height: 500px;
  }
 .card-img-top{
    height: 250px;
}
.card-body {
    padding: 1.25rem 1.25rem 0 1.25rem;
}

@media only screen and (max-width: 1200px) {
  .card-title {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .courseDetails {
    display: none;
  }
  .hideBR {
    display: none;
  }
  ::v-deep.btn {
    background-color: #EB0A1E;
    border-color: white;
    font-size: 1rem;
    border-radius: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {

  .logo {
    height: 35px;
  }
  .btn {
    width: 65%;
  }
  .sliderdetails {
    font-size: 10pt;
  }
  ::v-deep .card-title {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 990px) {
  #carousel-1 {
    display: none;
  }
  #carouselMob {
    display: block;
  }
  .logo {
    margin-top: 5%;
    width: auto;
    float: none;
  }
  
}
@media only screen and (max-width: 768px) {
  ::v-deep .carousel-control-next {
    display: none;
  }
  ::v-deep .carousel-control-prev {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .btn {
    width: 100%;
  }
  ::v-deep .carousel-control-prev{
    display: none;
  }
  ::v-deep .carousel-control-next{
    display: none;
  }
}
</style>
