<template>
  <div class="home">
      <template v-if="this.okfound == 'Y' & this.course !== null">
        <div id="slider">
          <HomeTab
            :Year="season"
            :courseCode="course"
            :reportURL="currentReport"
            :currentReport="reportTitle"
            :currentTitle='currentTitle'
            :config="config"
          />
        </div>
      </template>
      <div>
        <Slider :Year="tm_params.tm_params.season_code" :config="config" :DEFIMAGESAMS3="DEFIMAGESAMS3"/>
      </div>
      <div>
        <Cubes :config="config"/>
      </div>
      <div id="footer">
        <Footer :white="white" :config="config"/>
      </div>
  </div>
</template>

<script>
import Slider from "@/components/slider.vue";
import Cubes from "@/components/cubes.vue";
import Footer from "@/components/footer.vue";
import HomeTab from "@/components/Hometabone.vue";
import apiCall from "@/services/api.js";

export default {
  name: "home",
  props:["config"],
  components: {
    Slider,
    Cubes,
    Footer,
    HomeTab,
  },
  data: function() {
    return {
      tm_params: [],
      reports: [],
      course: [],
      season: [],
      white: true,
    };
  },
  computed: {
    currentTitle() {
      if (Array.isArray(this.reports))
        if (this.tm_params.rounds_played == '0') {
          return this.reports[this.reports.length - 1].report_title; 
        } else {
          return this.reports[this.reports.length - 4].report_title;
        }
        else return this.reports.report_title;
    },
    currentReport() {
      if (Array.isArray(this.reports))
        if (this.tm_params.rounds_played == '0') {
          return this.reports[this.reports.length - 1].report_url; 
        } else {
          return this.reports[this.reports.length - 4].report_url;
        }
        else return this.reports.report_url;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_APIFULL_URL +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_API +
              this.config.VUE_APP_ID +
              "/cache/" +
              this.config.VUE_APP_ID +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            this.config.VUE_APP_ID +
            "/cache/" +
            this.config.VUE_APP_ID +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  //  metaInfo() {
  //    //Injects Meta and Title to Head
  //    return {
  //      title: this.title.content,
  //      meta: [
  //        {
  //          name: this.description.name,
  //          content: this.description.content
  //        }
  //      ]
  //    };
  //  },
        created() {

    apiCall 
      .tmParamsBase()
      .then(({ data }) => {
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        this.tm_params = data;
        this.course = data.code;
        this.okfound = data.ok_found;
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

};
</script>
<style scoped>
/* home partial css */
::v-deep .card-title {
  font-weight: 700;
  font-size: 15pt;
}
::v-deep .table-responsive.homeresponse {
  margin-bottom: 0px;
}
.homeresponse {
  margin-bottom: 0px;
}
.cell{
  width: 10%;
  text-align: center;
  font-size: 11pt;
}

::v-deep .pos{
  color: #212529;
  font-size: 10pt;
  background-color: transparent;
}
::v-deep h2.fullnameandDates {
  font-size: 11pt;
  padding: 20px;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}
/* End of partial css */
::v-deep img.app {
  width: 140px!important;
}
#slider{
  padding-top: 50px;
}
.homeSplash {
  margin-top: -8%;
  width: 100%;
}
::v-deep .jumbotron {
  position: absolute;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 22rem 28rem 22rem 6rem;
}
::v-deep .display-3 {
  color: white;
  font-size: calc(2vw + 2vh);
  text-shadow: 2px 2px 4px #000000;
  width: 80%;
  font-weight: 400;
  line-height: 1.2;
}
::v-deep .lead {
  color: #EB0A1E;
  font-size: 3rem;
  font-weight: 500;
  font-style: italic;
  margin-top: 40px;
  margin-bottom: 55px;
}
.btn {
  background-color: transparent;
  border-color: white;
  font-size: 1.5rem;
  border-radius: 0;
  width: 25%;
}
::v-deep .carousel {
  position: relative;
}
#social {
  padding: 50px;
}

@media only screen and (max-width: 768px) {

  .lead {
    font-size: 2rem;
  }
  .display-3 {
    color: white;
    font-size: calc(2vw + 2vh);
    text-shadow: 2px 2px 4px #000000;
    width: 100%;
    font-weight: 400;
    line-height: 1.2;
  }
  .btn {
    background-color: transparent;
    border-color: white;
    font-size: 15px;
    border-radius: 0;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}
@media only screen and (max-width: 500px) {
  #slider{
    padding:50px 20px;
  }
}
@media only screen and (max-width: 425px) {
  .btn {
    width: 50%;
  }
  ::v-deep .carousel-item {
    height: 350px;
  }
}
</style>
