<!-- Nav Menu -->

<template>
  <div id="app" :class="{nooverflow : this.$route.name != 'oom'}">
    <Menu :route="route" :config="env"/>
    <transition name="fade" appear>
      <router-view
        :config="env"
        :key="$route.fullPath"
    /></transition>
    <div class='foot'>
      <b-row>
        <b-col>
          <span class='footList'>
          <p style="text-transform: initial;margin-top: 2.9em; padding-left: 30px;">
             ©  {{new Date().getFullYear()}} Toyota Tour
          </p>
          </span>
        </b-col>
        <b-col>
          <span class='copyRight'>
            <a href="https://ocs-sport.com" target='_blank'>
            <b-img class='ocsLogo' src="https://assets.asiantour.com/toyota-tour/2024/02/OCS_Sport_Logo_2017_WO.png"></b-img>
            </a>
          </span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
::v-deep .nooverflow {
  overflow: hidden;
}
/* .overflowPage {
  overflow: hidden;
} */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14pt;
}
li.nav-item::marker {
  color: transparent;
}
.footList{
  text-transform: capitalize;
    font-size: 9pt;
}
.foot{
  color: #fdfff7;
    background-color: #000;
}
.copyRight{
    float: right;
    margin-top: 1.3em;
    padding-right: 30px;
}
.ocsLogo{
    width: 50px;
    margin-bottom: 20px;
    display: block;
    position: relative;
}
@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
  }
}
</style>
<script>
  <meta name="apple-itunes-app" content="app-id=1522698750"></meta>
</script>
<script>
import Menu from "@/components/menu.vue";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      env:[]
    };
  },
  mounted() {
    this.env = process.env
  },
  computed:{
    route(){
      return this.$router.history.current.path
    }
  },
  methods: {
    // Define method that will use the payload to update the data property
    setSeason(payload) {
      this.season = payload.season;
    },
    setCourse(payload) {
      this.course = payload.course;
    },
    setReport(payload) {
      this.currentReport = payload.currentReport;
    },
    setTitle(payload) {
      this.currentTitle = payload.currentTitle;
    },
  },
};
</script>
